<template>
    <div v-loading.fullscreen.lock="loading">
        <the-header/>
        <section class="banner-section">
            <div class="container">
                <div class="banner-caption">
                    <h2>NMRA Certified Online Employee Training</h2>
                    <p>Online employee training that enhances customer satisfaction, reduces risks, and boosts revenue</p>
                </div>
            </div>
        </section>
        <div class="content-area" style="padding-top: 20px">
            <div class="container-fluid">
                <h5 class="mb-5 text-center" style="
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    color: #32325d;
">
                    If you are a current member of the NMRA, you will receive special pricing by selecting the box next to the “Already a member of the NMRA”. Enter your Member ID or name of your restaurant to receive your discount. After agreeing to the Service Activation Agreement and paying for the course(s), you will be sent a Welcome Email and can continue directly to the site to login. If you have any questions or don’t see a course you need, please contact us at <a :href="'mailto:' + infoEmail" class="linkColor">{{ infoEmail }}</a
                    >.
                </h5>
                <div class="row">
                    <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 form-section custom-form responsive-order-1">
                        <validation-observer v-slot="{ handleSubmit }" ref="formValidator" class="custom-bg-mobil">
                            <form role="form" @submit.prevent="handleSubmit()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4 style="color: #444c57" class="">Your Details</h4>
                                        <hr />
                                    </div>
                                    <br />
                                </div>

                                <div class="form-row">
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Company Name </label>
                                        <base-input type="text" name="Company name" placeholder="Company Name" v-model="employee.company_name"> </base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">First Name <span class="req"> *</span></label>
                                        <base-input type="text" name="First name" placeholder="First Name" rules="required" v-model="employee.first_name"> </base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                        <base-input type="text" name="Last name" placeholder="Last Name" rules="required" v-model="employee.last_name"> </base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Email <span class="req"> *</span></label>
                                        <base-input type="text" name="Email" placeholder="Email" rules="required" v-model="employee.email"> </base-input>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Phone <span class="req"> *</span></label>
                                        <base-input name="Telephone" placeholder="Phone" rules="required" v-model="employee.phone" @input="acceptNumber"> </base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Address <span class="req"> *</span></label>
                                        <base-input type="text" name="Address" placeholder="Address" v-model="employee.address"></base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">City <span class="req"> *</span></label>
                                        <base-input type="text" name="City" placeholder="City" v-model="employee.city"> </base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">State <span class="req"> *</span></label>
                                        <base-input type="text" name="State" placeholder="State" v-model="employee.state"> </base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Zip Code <span class="req"> *</span></label>
                                        <base-input type="number" name="Zip code" placeholder="Zip Code" v-model="employee.zipcode"> </base-input>
                                    </div>
                                </div>
                                <hr />
                                <div class="row" v-if="lead_id">
                                    <h3 style="color: #444c57" class="mt-2 ml-2">Login Information</h3>
                                </div>
                                <hr v-if="lead_id" />
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <label class="form-control-label">Username <span class="req"> *</span></label>
                                        <base-input type="test" name="Username" placeholder="Username" readonly v-model="employee.email"> </base-input>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-control-label">Password <span class="req"> *</span></label>
                                        <base-input type="passwordFieldType" name="Password" placeholder="Password" v-model="employee.password"> </base-input>
                                        <div class="password-eye passwordview">
                                            <span @click.prevent="switchVisibility"><i class="fa fa-eye" title="Show Password"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </validation-observer>
                    </div>
                    <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 responsive-order">
                        <div class="row">
                            <div class="col-md-12 mt-0 mb-3 text-center bg-gray-100 p-2 shadow-sm">
                                <h5 class="mb-0 new-signup">
                                    Not a member,
                                    <a class="linkColor" target="__blank" href="https://www.nmrestaurants.org/nmra-membership/"> click here </a>
                                    <span>to join.</span>
                                </h5>
                            </div>

                            <div class="col-md-12 mt-2">
                                <div class="row priceGrid custom-bg" style="font-weight: bolder">
                                    <div class="col-lg-6 col-md-6 col-sm-8 col-8 price-info">Courses</div>
                                    <div class="col-md-6 price-info ">Pricing</div>
                                </div>
                                <el-checkbox-group v-model="checked_coursefolders">
                                    <span class="odd-row" v-for="option in coursefolders" :key="option.id">
                                        <div class="row priceGrid">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                                <el-popover :ref="`fromPopOver${option.id}`" placement="top-start" width="500" trigger="hover" :key="option.id" :id="option.id">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                                {{ courses.name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox :label="option.id">
                                                    <span>{{ option.coursefolder_name }}</span>
                                                </el-checkbox>

                                                <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                            </div>
                                            <div class="col-md-6 text-center">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                            <br />
                                        </div>
                                    </span>
                                </el-checkbox-group>
                                <el-checkbox-group v-model="checked_courses">
                                    <span class="odd-row" v-for="option in course" :key="option.id">
                                        <div class="row priceGrid">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                                <el-checkbox :label="option.id">{{ option.course_name }}</el-checkbox>
                                            </div>
                                            <div class="col-md-6 text-center">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                        </div>
                                        <br />
                                    </span>
                                </el-checkbox-group>
                                <el-checkbox-group v-model="checked_other_courses">
                                    <span class="odd-row" v-for="option in basicPackage1" :key="option.id">
                                        <div class="row priceGrid">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                                <el-popover :ref="`fromPopOver${option.id}`" placement="top-start" width="500" trigger="hover" :key="option.id" :id="option.id">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                                {{ courses.name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox :label="option.id">{{ option.coursefolder_name }}</el-checkbox
                                                ><span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.non_member_price }}</span>
                                            </div>
                                        </div>
                                    </span>
                                </el-checkbox-group>

                                <el-checkbox-group v-model="checked_other_courses">
                                    <span class="odd-row" v-for="option in basicPackage2" :key="option.id">
                                        <div class="row priceGrid">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                                <el-popover :ref="`fromPopOver${option.id}`" placement="top-start" width="500" trigger="hover" :key="option.id" :id="option.id">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                                {{ courses.name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox :label="option.id" v-if="checked_coursefolders.length > 0" disabled>{{ option.coursefolder_name }}</el-checkbox>
                                                <el-checkbox :label="option.id" v-else-if="checked_coursefolders.length == 0">{{ option.coursefolder_name }}</el-checkbox
                                                ><span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.non_member_price }}</span>
                                            </div>
                                        </div>
                                        <br />
                                    </span>
                                </el-checkbox-group>
                            </div>
                            <br />
                        </div>

                        <div class="col-6 mt-3 pl-0" v-if="lead_id">
                            <p class="d-flex justify-content-between" v-if="discount"><span><strong>Sub Total</strong></span><span class="font-weight-bold">{{ formatPrice(sub_total) }}</span></p>
                            <p class="d-flex justify-content-between" v-if="discount"><span><strong>Discount</strong></span><span class="font-weight-bold">{{ formatPrice(discount) }}</span></p>
                            <p class="d-flex justify-content-between"><span><strong>Total cost</strong></span><span class="font-weight-bold">{{ formatPrice(total_cost) }}</span></p>
                        </div>

                        <div class="row align-items-end mt-2">
                            <div class="col-md-6 col-12">
                                <div class="form-group promo_inputBOX mb-0">
                                    <label>Promo Code
                                    </label>
                                    <input type="text" class="form-control" id="promo-code" placeholder="Enter your Promo Code"  v-model="promocode">
                                </div>
                            </div>
                            <div class="col-md-3  col-6 mt-md-0 mt-3">
                                <base-button class="btn-success" @click.prevent="calculatePrice">Apply Coupon</base-button>
                            </div>
                            <div class="col-12 mt-3 d-flex align-items-end mb-3">
                                <base-button class="btn-info" @click.prevent="calculatePrice">
                                    <span v-if="!lead_id">Calculate</span>
                                    <span v-else>Re-estimate</span>
                                </base-button>
                                <div class="d-inline-block" v-if="(checked_courses.length || checked_coursefolders.length || checked_other_courses.length) && lead_id">
                            <base-button class="mx-2" @click.prevent="checkIfNMRAMember">Sign Up</base-button>
                        </div>
                        <div class="d-inline-block" v-else>
                            <base-button v-if="!showPricePlan" class="basebutton mx-2" disabled>Sign Up</base-button>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="agreementModal" class="user-modal">
            <h3 slot="header">Terms and Conditions</h3>
            <form>
                <div class="agreement-content">
                    <agreement type="individual"></agreement>
                </div>
                <div class="text-center mt-2">
                    <base-button type="success" @click.prevent="finalCreateAccount"> I Agree </base-button>
                    <base-button type="danger" @click.prevent="cancelAgreement"> Cancel </base-button>
                </div>
            </form>
        </modal>
        <modal :show.sync="showPaymentOption">
            <h4 slot="header" style="color: #444c57" class="modal-title mb-0">Payment</h4>
            <credit-card v-if="showPaymentOption" type="individual" :monthlyAmount="sub_total" :yearlyAmount="perYearCost" :onlySexualCourse="0" :city="employee.city" :state="employee.state" :address="employee.address" :zip="employee.zipcode" :enablePaymentButton="enablePaymentButton" v-on:payClicked="payClicked" />
        </modal>
        <modal :show.sync="paymentModalMessage">
          <h1 class="text-capitalize text-center text-strong m-0 payment-modal-text text-danger font-weight-500">Your payment is processing...</h1>
        </modal>
        <the-footer/>
    </div>
</template>
<script>
import { Checkbox, CheckboxGroup, Option, OptionGroup, Select, Table, TableColumn } from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import CreditCard from "@/nmra/partials/CreditCard";
import Agreement from "@/views/Pages/Agreement.vue";
import TheFooter from "@/nmra/partials/Footer.vue";
import TheHeader from "@/nmra/partials/Header.vue";

export default {
    name: "register",
    components: {
        TheHeader,
        TheFooter,
        Agreement,
        CreditCard,
        [Select.name]: Select,
        [Option.name]: Option,
        [OptionGroup.name]: OptionGroup,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
    },

    data() {
        return {
            loading: false,
            paymentModalMessage: false,
            baseUrl: this.$baseUrl,
            agreementModal: false,
            already_member: false,
            member_id: "",
            courseFolderSelectionFocused: false,
            courseSelectionFocused: false,
            showPaymentOption: false,
            search: "",
            otherCourses: [],
            courses: [],
            course: "",
            coursefolders: [],
            checked_courses: [],
            basicPackage1: [],
            basicPackage2: [],
            checked_coursefolders: [],
            checked_other_courses: [],
            formData: {
                employee_first_name: "",
                employee_last_name: "",
                user_type: "",
                employee_address: "",
                employee_city: "",
                employee_state: "",
                employee_zipcode: "",
                employee_email: "",
                employee_phone_num: "",
                password: "",
                address: "",
            },
            employee: {
                company_name: "",
                first_name: "",
                last_name: "",
                emial: "",
                phone: "",
                address: "",
                city: "",
                state: "",
                zipcode: "",
                username: "",
                password: "",
            },
            passwordFieldType: "password",
            showPricePlan: false,
            totalPricePerYear: 0,
            discount: 0,
            total_cost: 0,
            total_discount: "",
            discountperlocation: "",
            perYearCost: "",
            discount_msg: "",
            lead_id: "",
            siteName: "",
            infoEmail: "nmra@train321.com",
            inputStyle: "",
            promocode: "",
            enablePaymentButton: false,
            isSignedUpClicked: false,
        };
    },

    mounted() {
        this.siteName = Dynamic.SITE_NAME;
        if (this.$route.query.inner) {
            this.$gtag.event("S.H.P. Individual signup", { method: "Google" });
        } else if (this.$route.query.course_type) {
            this.$gtag.event("Sexual Harassment Prevention Individual signup", {
                method: "Google",
            });
        } else {
            this.$gtag.event("Individual signup", { method: "Google" });
        }
    },
    async created() {
        if (this.$route.query.redirection === "yes") {
            this.employee.first_name = localStorage.getItem("fname");
            this.employee.last_name = localStorage.getItem("lname");
            this.employee.email = localStorage.getItem("email");
            this.checked_courses = JSON.parse(localStorage.getItem("courses"));
        } else {
            localStorage.removeItem("fname");
            localStorage.removeItem("lname");
            localStorage.removeItem("email");
            localStorage.removeItem("courses");
        }
        this.$http.get("nmra/discountRules").then(response => {
            for (let course of response.data.courses) {
                let obj = {
                    checked: false,
                    id: course.id,
                    course_name: course.name,
                    member_price: course.member,
                    non_member_price: course.non_member,
                    course_type: course.course_type,
                    cost: course.cost
                };
                this.courses.push(obj);
            }
            this.course = this.courses;
            for (let coursefolder of response.data.course_folders) {
                let obj1 = {
                    id: coursefolder.id,
                    coursefolder_name: coursefolder.folder_name,
                    member_price: coursefolder.member,
                    non_member_price: coursefolder.non_member,
                    attachedCourses: coursefolder.active_courses
                };

                this.coursefolders.push(obj1);
            }
        });
    },
    methods: {
        calculatePrice: function () {
            this.loading = true;
            this.isSignedUpClicked = true;
            this.signupClicked();
        },
        finalCreateAccount() {
            this.agreementModal = false;
            this.showPaymentOption = true;
        },
        showDone() {
            this.courseSelectionFocused = true;
        },
        doneClicked() {
            this.courseSelectionFocused = false;
        },
        showDoneButton() {
            this.courseFolderSelectionFocused = true;
        },
        doneButtonClicked(e) {
            this.courseFolderSelectionFocused = false;
        },
        cancelAgreement() {
            this.agreementModal = false;
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        formatPrice(value) {
            return "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },
        acceptNumber() {
            var x = this.employee.phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.employee.phone = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        showAgreement() {
            if (this.employee.password == "") {
                return Swal.fire({
                    title: "Error!",
                    text: "Please enter password to continue.",
                    icon: "error",
                });
            }
            this.formData = {
                employee_company_name: this.employee.company_name ? this.employee.company_name : "individual",
                employee_first_name: this.employee.first_name,
                employee_last_name: this.employee.last_name,
                user_type: "individual",
                employee_address: this.employee.address,
                employee_city: this.employee.city,
                employee_state: this.employee.state,
                employee_zipcode: this.employee.zipcode,
                employee_email: this.employee.email,
                employee_phone_num: this.employee.phone,
                password: this.employee.password,
                address: this.employee.address,
                courses: this.checked_courses,
                courseFolders: this.checked_coursefolders,
                otherCourseFolders: this.checked_other_courses,
                already_member: this.already_member,
                member_id: this.member_id,
                i_agree: true,
            };
            this.agreementModal = true;
        },
        checkIfNMRAMember() {
            this.isSignedUpClicked = false;
            if (!this.already_member) {
                Swal.fire({
                    title: "Already a NMRA Member?",
                    html: '<a target="__blank" href="https://www.nmrestaurants.org/nmra-membership/"> Click here</a> to join.',
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success btn-fill",
                    cancelButtonClass: "btn btn-danger btn-fill",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    buttonsStyling: false,
                    icon: "question",
                }).then((result) => {
                    if (result.value) {
                        this.inputStyle = "border:2px solid #f8981c;";
                        this.already_member = true;
                    } else {
                        this.signupClicked();
                    }
                });
            } else {
                this.signupClicked();
            }
        },
        signupClicked() {
            if (this.employee.first_name && this.employee.last_name && this.employee.address && this.employee.city && this.employee.state && this.employee.zipcode && this.employee.email) {
                this.$http
                    .post("nmra/lead", {
                        company_name: this.employee.company_name ? this.employee.company_name : "individual",
                        first_name: this.employee.first_name,
                        last_name: this.employee.last_name,
                        number_of_locations: 1,
                        number_of_employees: 1,
                        email: this.employee.email,
                        phone_num: this.employee.phone,
                        user_type: "individual",
                        already_member: this.already_member,
                        member_id: this.member_id,
                        course_ids: this.checked_courses,
                        course_folders: this.checked_coursefolders,
                        other_courses: this.checked_other_courses,
                        promocode: this.promocode,
                    })
                    .then((resp) => {
                        this.loading = false;
                        this.lead_id = resp.data.user_id;
                        this.total_cost = resp.data.total_cost;
                        this.totalPricePerYear = resp.data.totalPricePerYear;
                        this.discount = resp.data.discount;
                        this.sub_total = resp.data.sub_total;
                        if(this.isSignedUpClicked == false) {
                            this.showAgreement();
                        }
                    }).catch((error) => {
                        this.loading = false;
                        return Swal.fire({
                            title: "Error!",
                            html: error.response.data.message,
                            icon: "error",
                        })
                    });
                } else {
                this.loading = false;
                this.paymentModalMessage = false;
                Swal.fire({
                    title: "Error!",
                    text: "Please fill all mandatory fields.",
                    icon: "error",
                });
            }
        },

        payClicked(cardData) {
            this.loading = true;
            this.paymentModalMessage = true;
            let payment = {
                payment_type: cardData.paymentType,
                cardholder_street_address: cardData.address + "," + cardData.city + "," + cardData.state,
                cardholder_zip: cardData.zip,
                transaction_amount: "",
                token: cardData.token,
            };

            if (payment.payment_type == "monthly") {
                payment.transaction_amount = this.sub_total.toFixed(2);
            }
            if (payment.payment_type == "yearly") {
                payment.transaction_amount = this.perYearCost.toFixed(2);
            }
            this.formData.payment = payment;
            this.formData.employee_address = cardData.address;
            this.formData.address = cardData.address;
            this.formData.employee_state = cardData.state;
            this.formData.employee_city = cardData.city;
            this.formData.employee_zipcode = cardData.zip;
            this.loading = false;
            this.createAccount(this.formData);
        },
        createAccount(formDataSubmitted) {
            delete this.$http.defaults.headers["authorization"];
            this.loading = true;
            this.$http
                .post("nmra/register", formDataSubmitted)
                .then((resp) => {
                    let ids = [];
                    let obj = {
                        id: resp.data.id,
                    };
                    ids.push(obj);
                    this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                    Swal.fire({
                        title: "Success!",
                        html: `Your account has been created and is now active! <a href="/#/nmra-login">Click here</a> to Login`,
                        icon: "success",
                        confirmButton: "btn btn-success",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        if (result.value) {
                            this.$router.push("/nmra-login");
                        }
                    });
                })
                .catch((error) => {
                    this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                    return Swal.fire({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                    });
                });
        },
    },
};
</script>
<style scoped>

.payment-modal-text {
    animation: textBlink 2s linear infinite;
}
@keyframes textBlink{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
.banner-caption h2 {
    font-size: 30px;
}

.form-section {
    background-color: transparent;
    border-right: 1px solid #dedede;
}

hr {
    margin-top: 2px !important;
    margin-bottom: 20px !important;
}
#selected_course li {
    font-size: 0.89em;
}
#serviceAgreement {
    float: left;
    height: 300px;
    overflow: auto;
}
#serviceAgreement p {
    font-size: 0.81rem;
    text-align: justify;
}
.el-select-group__title {
    font-size: 14px !important;
    font-weight: bolder;
    color: #28c0e7;
}
.el-select-dropdown__item {
    font-size: 13px !important;
}
.reduceFont {
    font-weight: 400 !important;
}
.price-area .row {
    margin-top: 5px;
}
.bg-gradient-primary {
    background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}
.req {
    color: red;
}
.knowMore-info {
    font-size: 13px;
    margin-left: 6px;
    text-decoration: underline;
    font-style: italic;
    color: #ef8f1d !important;
    cursor: pointer;
}
.price-info {
    font-size: 13px;
    font-weight: bold;
}
.priceGrid {
    border-bottom: 1px solid #dedede;
    padding-top: 8px;
}
.price-info {
    display: flex;
    align-items: center;
}

.provideScroller {
    padding-top: 10px;
    overflow-y: auto !important;
    max-height: 250px !important;
}
.login-inputs {
    padding-top: 20px;
}
.ctm-btn {
    background-color: #ef8f1d;
    color: #ffffff;
    border-radius: 0 !important;
    font-size: 17px;
    margin-right: 5px;
}
.login {
    margin-right: 0px !important;
}
body:has(.form-section) .content-area{
    min-height: 66vh !important;
}

@media (min-width: 992px) {
    .pt-lg-9,
    .py-lg-9 {
        padding-top: 3rem !important;
    }
    .el-checkbox__input {
    cursor: pointer;
    line-height: 1;
    display: inline-block;
    height: 20px;
    width: 20px;
}

}

@media only screen and (max-width: 991px) {

.row.priceGrid > div{
    display: flex;
}
span.knowMore-info {
    white-space: nowrap;
    margin-left: 4px;
    display: -webkit-box;
}
}

@media only screen and (max-width: 768px){
    .row.priceGrid > div[data-v-3239aa7b] {
    display: unset !important;
 }
 span.knowMore-info {
    margin-left: 22px;
 }
.el-checkbox__input {
    display: inline-block ;
    height: 20px !important;
    width: 20px !important;
}
}
</style>
